<template>
  <div>
    <nav-bar v-if="!isWeiXin()" :title="$t('e-Rith錢包')" :isFixed="true" :goBack="goBack" :bgc="'background: #ffffff'" />
    <div :class="['container',{'marginTop':!isWeiXin()}]">
      <div class="balance-tip" @click="getSetPassword" v-if="!hasPayPwd">
        <div>
          <img class="tips" src="@/assets/image/balance/tips.png" alt="" />
        </div>
        <div class="text">{{ $t("餘額暫不可用請先設定付款密碼") }}</div>
        <div>
          <img class="arrow" src="@/assets/icons/arrow-right.png" alt="" />
        </div>
      </div>
      <div class="Available-balance">
        <div class="Available-content">
          <div class="Available-text">
            {{ $t("可用餘額(KHD)") }}
            <img
              v-if="isViewBalance"
              @click="viewBalance"
              src="@/assets/image/balance/eyes.png"
              alt=""
            />
            <img
              v-else
              @click="viewBalance"
              src="@/assets/image/balance/eyesClose.png"
              alt=""
            />
          </div>
          <div class="money" v-if="isViewBalance">
            <!-- <div>{{ userbalance.split(".")[0] || '0' }}</div>
            <div>.{{ userbalance.split(".")[1] || "00" }}</div> -->
            <div>{{TruncateNumberToTwoDecimals(userbalance||0)}}</div>
          </div>
          <div class="money" v-else>
            <div>
              ******
            </div>
          </div>
        </div>
      </div>
      <div class="balance-list">
        <div class="title">
          <div class="left">{{ $t("餘額明細") }}</div>
          <div
            class="right"
            @click="getBalanceDetial"
            v-if="balanceList.length > 0"
          >
            {{ $t("查看全部")
            }}<img src="@/assets/icons/arrow-right.png" alt="" />
          </div>
        </div>
        <div
          class="balance-list-content"
          ref="container"
          @scroll="scrollPage"
          v-if="balanceList.length > 0"
        >
          <div
            class="balance-item"
            v-for="(item, index) in balanceList"
            :key="index"
          >
            <div>
              <div class="balance-type">{{ item.type }}</div>
              <div class="balance-date">{{ item.createTime }}</div>
            </div>
            <div
              :style="item.price > 0 ? 'color: #F82207;' : ''"
              class="amount"
            >
              {{ item.price > 0 ? "+" + TruncateNumberToTwoDecimals(item.price) : TruncateNumberToTwoDecimals(item.price) }}
            </div>
          </div>
        </div>
        <div v-else class="empty">
          <img src="@/assets/image/balance/empty.png" alt="" />
          <div>{{ $t("暫無餘額明細") }}</div>
        </div>
      </div>
      <div class="balance-message">
        {{ $t("本頁面只展示近一個月的記錄") }}
      </div>
    </div>
  </div>
</template>

<script>
import { getCheckList, getBalance, getUserInfo } from "@/api/balance";
import i18n from "@/i18n";
import Mixins from "@/utils/mixins";
export default {
  mixins: [Mixins],
  data() {
    return {
      formPage: {
        pageIndex: 1,
        pageSize: 10
      },
      balanceList: [],
      userbalance: "",
      isViewBalance: true,
      hasPayPwd: true,
    };
  },
  async created() {
    if (this.$route.query.langEnv == 2) {
      i18n.global.locale.value = "en";
    } else if (this.$route.query.langEnv == 3) {
      i18n.global.locale.value = "zh";
    }
    const res =  window.localStorage.getItem("isViewBalance");
    this.isViewBalance=res === 'true' ? true : false;
    await this.getUserBalance();
    await this.getList();
  },
  mounted() {
    document.title = this.$t("e-Rith錢包");
  },
  methods: {  
    TruncateNumberToTwoDecimals(num) {
      let str = num.toString();
      if (!str.includes('.')) {
        return str + '.00';
      }
      let parts = str.split('.');
      return parts[0] + '.' + (parts[1].length >= 2 ? parts[1] : parts[1] + '0');
    },
    goBack () {
      this.$router.back()
    },
      // 跳转订单详情
    getOrder(orderNo) {
      var _this = this;
      var func1 = function() {
        mmm.gotoOrderDetail(orderNo);
      };
      var func2 = function() {
        window.webkit.messageHandlers.gotoOrderDetail.postMessage(orderNo);
      };
      var func3 = function() {
        return;
        // _this.$router.push({ name: "Product", params: { id: id } });
      };
      this.excFunc(func1, func2, func3);
    },
    viewBalance() {
      this.isViewBalance = !this.isViewBalance;
      window.localStorage.setItem("isViewBalance", this.isViewBalance);
    },
    // 跳转设置密码
    getSetPassword() {
      var _this = this;
      var func1 = function() {
        mmm.goToSetPassword();
      };
      var func2 = function() {
        window.webkit.messageHandlers.goToSetPassword.postMessage("");
      };
      var func3 = function() {
        _this.$router.push("/setpPayPass");
        return;
      };
      this.excFunc(func1, func2, func3);
    },
    async getUserBalance() { 
      const langEnv = this.$route.query.langEnv;
      const userInfo = await getUserInfo({ type: 2 }, langEnv);
      const res = await getBalance(langEnv);
      this.hasPayPwd = userInfo.data.hasPayPwd;
      this.userbalance = String(res.data);
    },
    async scrollPage() {
      const container = this.$refs.container;

      if (
        Math.ceil(container.scrollTop) + container.clientHeight >=
        container.scrollHeight
      ) {
        this.formPage.pageIndex += 1;
        await this.getList();
      }
    },
    getBalanceDetial() {
      this.$router.push({
        path: "/balanceDetial",
        query: {
          langEnv: this.$route.query.langEnv
        }
      });
    },
    async handleScroll(event) {
      // 添加你的业务逻辑，例如检查是否滚动到底部
      const windowHeight = window.innerHeight;
      const documentHeight = Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight
      );
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop + windowHeight >= documentHeight) {
        // 加载更多数据或执行其他操作
        this.formPage.pageIndex += 1;
        await this.getList();
      }
    },
    async getList() {
      const langEnv = this.$route.query.langEnv;
      const res = await getCheckList(this.formPage, langEnv);
      if (res.resultID === 1200) {
        if (this.formPage.pageIndex > 1) {
          this.balanceList = [...this.balanceList, ...res.data.list];
        } else {
          this.balanceList = res.data.list;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.marginTop{
  margin-top:44px;
}
.container {
  padding: 16px;
  .balance-tip {
    width: 100%;
    padding: 12px 16px;
    background: #f0fbda;
    border-radius: 12px 12px 12px 12px;
    border: 1px solid #bce382;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    .text {
      flex: 1;
    }
    .arrow {
      width: 16px;
    }
    .tips {
      width: 24px;
      margin-right: 4px;
    }
  }
  .Available-balance {
    background: url("../../assets/image/balance/balancebg.png") center no-repeat;
    background-size: 100% 100%;
    height: 120px;
    margin-bottom: 16px;
    .Available-content {
      padding: 20px;
      .Available-text {
        color: #1a1a1a;
        font-size: 15px;
        margin-bottom: 14px;
        display: flex;
        align-items: center;
        img {
          width: 16px;
          margin-left: 4px;
        }
      }
      .money {
        font-family: PingFang SC, PingFang SC;
        font-weight: 800;
        font-size: 36px;
        color: #1a1a1a;
        line-height: 40px;
        display: flex;
        align-items: end;
      }
    }
  }
  .balance-list {
    background-color: #fff;
    border-radius: 10px;

    .title {
      display: flex;
      padding: 20px 16px;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #f8f8f8;
      .left {
        font-family: PingFang SC, PingFang SC;
        font-weight: bold;
        font-size: 17px;
        color: #1a1a1a;
        line-height: 24px;
      }
      .right {
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #999999;
        display: flex;
        align-items: center;
      }
      img {
        width: 16px;
        height: 16px;
      }
    }
    .balance-list-content {
      height: 500px;
      overflow: auto;
    }
    .balance-item {
      padding: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #f8f8f8;
      .balance-type {
        color: #1a1a1a;
      }
      .balance-date {
        font-size: 12px;
        color: #999999;
      }
      .amount {
        color: #1a1a1a;
        font-weight: bold;
      }
    }
  }
  .balance-message {
    margin: 32px auto;
    text-align: center;
  }
  .empty {
    text-align: center;
    padding: 160px 0;
    img {
      width: 120px;
      margin: auto;
    }
  }
}
/* 去掉ios横向滚动条 */
*::-webkit-scrollbar:horizontal {
  width: 0;
  height: 0;
  display: none;
}
</style>
